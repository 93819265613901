<script setup>
import AccountNameUpdate from './AccountNameUpdate.vue'
import AccountEmailUpdate from './AccountEmailUpdate.vue'
import AccountPasswordUpdate from './AccountPasswordUpdate.vue'

import { ref, onMounted, computed, inject } from "vue"

import { useUsersStore } from "../stores/users";
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const userStore = useUsersStore()

const signout = async () => {
  await userStore.logout(pb)
  router.push('/login')
}

let pb

onMounted( async () => {
  pb = inject('client')
})

</script>

<template>
  <!-- <h3 class="separator-left text-2xl px-4 before:table before:content-['_'] before:basis-0 before:order-1 after:table after:content-['_'] after:basis-0 after:order-1 after:relative after:w-12 after:border-b-2 after:border-solid after:border-primary-100 after:mt-4 after:mb-4 after:clear-both">Account Settings</h3> -->
  <AccountNameUpdate class=""></AccountNameUpdate>
  <hr class="mb-4 w-11/12 mx-0 pr-4 sm:w-full md:w-11/12">
  <AccountEmailUpdate></AccountEmailUpdate>
  <!-- <hr class="mb-4 flex-1 lg:max-w-[76%] mx-4"> -->
  <hr class="mb-4 w-11/12 mx-0 pr-4 sm:w-full md:w-11/12">
  <AccountPasswordUpdate></AccountPasswordUpdate>
  <button @click="signout" class="btn btn-alert w-3/12 mt-4" type="button">Sign Out</button>
</template>
