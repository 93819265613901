<script setup>
import { ref, onMounted, computed, inject } from "vue"
import { useUsersStore } from "../stores/users";

const userStore = useUsersStore()


import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    newEmail: yup.string().email().required(),
  }),
});

const onEmailChange = handleSubmit( async (values, {resetForm, setFieldError}) => {
    try {
     await userStore.updateEmail(values.newEmail, pb)
    } catch (error) {
        if (Object.keys(error.data.data).length) {
            for (const item in error.data.data) {
                if (Object.hasOwnProperty.call(error.data.data, item)) {
                    setFieldError(item, error.data.data[item].message)
                }
            }
        
        } else {
            setFieldError('email', error.message)
        }
    }
});

const canSaveEmailChanges = computed(() => {  
    return !(newEmail.value == userStore.currentUser.email)
})

let pb

onMounted( async () => {
  pb = inject('client')
  
  newEmail.value = pb?.authStore?.model?.email ?? ''
})

const [newEmail, newEmailAttrs] = defineField('newEmail')

</script>

<template>
    <form @submit="onEmailChange">
  <div class="flex flex-wrap gap-4">
    <div class="flex sm:w-full md:w-8/12 w-8/12">
    <div class="w-6/12 sm:w-7/12">
      <label for="accountEmail" class="middle block m-0 text-sm font-normal leading-[1.5] py-[.5625rem] mb-4">Email</label>
    </div>
    <div class="w-6/12 sm:w-7/12">
      <input class="input"  id="accountEmail"  v-model="newEmail" v-bind="newEmailAttrs"  type="email">
      <span v-show="errors.newEmail" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': errors.newEmail}">{{ errors.newEmail }}</span>
    </div>
  </div>
    <div class="w-3/12">
      <input class="btn btn-primary disabled:opacity-25 disabled:cursor-not-allowed w-full" :disabled="!canSaveEmailChanges" type="submit" value="Save Changes">
    </div>
  </div>
</form>
  

</template>
