<script setup>
import { useRoute, useRouter, RouterLink } from 'vue-router';
import { onMounted, ref, provide } from 'vue';

const route = useRoute();
const router = useRouter()

const getChildRoutes = (parentName) => {
      const parentRoute = router.getRoutes().find(route => route.name === parentName)
      
      if (parentRoute && parentRoute.children) {
        return parentRoute.children.filter(each => each.meta?.showInSidebar ?? true)
      }
      
      return []
    }

    // Example usage
    const childRoutes = getChildRoutes('Preferences')
    console.log('childRoutes', childRoutes)

    console.log('router.getRoutes()', router.getRoutes())
    
const routes = ref(getChildRoutes('Preferences'))

const isCurrentRoute = (routeName) => {
  return route.name === routeName
}

const getActiveRouteClass = (routeName) => {
  if (isCurrentRoute(routeName)) {
    return 'is-active'
  }
  return ''
}

const blurred = ref(false)
// provide('blurred', blurred)

onMounted(() => {
//   window.ui.onBlur(() => {
//     blurred.value = true
//   })

//   window.ui.onFocus(() => {
//     blurred.value = false
//   })

//   window.router.onGoToPreferences(() => {
//     console.log('subPath', subPath)
//       router.push('/preferences/' + subPath)
//   })
})

</script>

<template>
  <div class="preferences window-drag">
    <div class="flex-nowrap h-dvh overflow-hidden">
      <div class="h-dvh">
        <div class="flex flex-wrap h-full window-drag">
          <div class="w-full px-2.5 sm:w-64  dark:bg-neutral-800 dark:text-white bg-neutral-200 text-neutral-950">
            <div class="mt-10 sm:mt-10"></div>
            <ul class="flex-nowrap flex-col p-0 m-0 list-none window-no-drag " :class="{'blurred': blurred}">
              <li v-for="path in routes" :key="path.path" :class="getActiveRouteClass(path.name)">
                <RouterLink
                  :to="'/preferences/' + path.path"
                  exactActiveClass="round"
                  class="px-4 py-3 block mb-0 no-underline leading-4 text-sky-600"
                  >{{ path.name}}</RouterLink
                >
              </li>
            </ul>
            <RouterLink
                  to="/"
                  class="btn btn-primary top-2.5 right-2.5 absolute sm:bottom-0 sm:right-auto sm:top-auto"
                  >Home</RouterLink
                >
          </div>
          <div class="w-full px-2.5 sm:px-4 flex-1 sm:max-w-[66.666667%]  mt-4 flex flex-col h-full">
            <h5 class="separator-left sticky text-xl mt-0 mb-2" :class="{'blurred': blurred}">{{ route.name }}</h5>
            <div class="window-no-drag flex-1 overflow-y-auto">
              <router-view/>
            </div>
            
          </div>
        </div>
      <div>
      </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.is-active a {
  @apply bg-sky-600 dark:bg-sky-900 text-white
}
 /* .menu a:not(.is-active):hover {
  background-color: var(--bg-color);
  color: var(--text-color);
 } */
  /* .sidebar {
    background-color: var(--secondary-callout-default);
    color: var(--white-text);
  } */
  .blurred {
    filter: grayscale(100%) opacity(0.5);
  }

  *,
a,
button {
  cursor: default;
  user-select: none;
  -webkit-user-drag: none;
}
  
</style>
