<script setup>
import { ref, onMounted, computed } from "vue"
import { useSettingsStore } from '../stores/settings'

const settingsID = 'settings-calendar-settings'

const store = useSettingsStore()

// const loadTheme = () => store.loadTheme()

// const calendars = computed({
//   get() {
//     return store.calendars
//   },
//   set(newValue) {
//     store.setTheme(newValue)
//   }
// })


const getID = (theme) => `${settingsID}-${theme}`

</script>

<template>
  <div class="flex flex-wrap">
    <div class="flex-1">
      <label for="calendarUpdateFrequency" class="middle block m-0 text-sm font-normal leading-7">Calendar Update Frequency</label>
    </div>
    <div class="w-32">
      <div class="join">
        <input id="calendarUpdateFrequency" class="round input join-item !rounded-l-md " type="number" v-model="store.calendarUpdateFrequency">
        <span class="px-2 !rounded-r-md join-item join-item-label">ms</span>
      </div>
        
    </div>
    <!-- <div class="cell medium-3">
      <h4 class="subheader">In MS</h4>
    </div> -->
  </div>
</template>
