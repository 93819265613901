// Pinia Store
import { defineStore } from 'pinia'
// import vuexStore from '@/store' // for gradual conversion, see fullUserDetails


export const useUsersStore = defineStore('users', {
  // convert to a function
  state: () => ({
    currentUser: {},
    me: {},
    isLoggedIn: false,
    users: []
  }),
  getters: {},
  actions: {
    checkLogin: async function(pb) {
        console.log('pb.authStore.isValid', pb.authStore.isValid)
        
        if (pb.authStore.isValid) {
            this.currentUser = pb.authStore.model
            // const record = await pb.collection('userScores').getFirstListItem(`user="${this.currentUser.id}"`, {
            //     // expand: 'relField1,relField2.subRelField',
            // });
            // console.log('record', record)
            this.isLoggedIn = true
        } else {
            this.isLoggedIn = false
            this.currentUser = {}
        }
        console.log('pb.authStore.model', pb.authStore.model)
    },
    getUserEmail: function(pb) {
        return pb.authStore?.model?.email
    },
    loginWithPCO: async function(pb) {
        const authData = await pb.collection('users').authWithOAuth2({ provider: 'planningcenter' });

        const user = await this.createUser({
            name: authData.meta.name,
            score: 0,
            user: authData.record.id
        })
        this.currentUser = authData.record
        this.currentUserScoreRecord = user.id

        this.me = authData.meta.rawUser.data

        this.isLoggedIn = true
        console.log('authData', authData)
  
        console.log(pb.authStore.isValid);
      console.log(pb.authStore.token);
      console.log(pb.authStore.model.id);
    },
    login: async function(email, password, pb) {
        try {
            const authData = await pb.collection('users').authWithPassword(
                email,
                password,
            );
            this.currentUser = authData.record
            console.log('authData', authData)
            this.isLoggedIn = true
        } catch (error) {
            console.error(error);
            throw error
        }
        
        
        
        // const user = await this.createUser({
        //     name: name,
        //     user: authData.record.id
        // })
        // this.currentUser = authData.record
        
    },
    logout: function(pb) {
        pb.authStore.clear();
        this.currentUser = {}
        this.isLoggedIn = false
    },
    registerUser: async function(email, password, passwordConfirm, pb) {
        const data = {
            email,
            // "emailVisibility": true,
            password,
            passwordConfirm,
            name,
        };
        
        try {
            const record = await pb.collection('users').create(data);
        console.log('record', record)
        // this.currentUser = authData.record
        this.isLoggedIn = true
        } catch (error) {
            console.error(error);
            throw error
        }
        
    },
    updateEmail: async function(email, pb) {
        try {
            const record = await pb.collection('users').requestEmailChange(email);
            console.log('record', record)
            
        } catch (error) {
            throw error
        }
    },
    updatePassword: async function(password, passwordConfirm, oldPassword, pb) {
        try {
            const data = {
                password,
                passwordConfirm,
                oldPassword
            }
            const record = await pb.collection('users').update(pb.authStore.model.id, data);
            console.log('record', record)
            
        } catch (error) {
            throw error
        }
    },
    resetPassword: async function(email, pb) {
        await pb.collection('users').requestPasswordReset(email);
    },
    async initializeUsers() {
        await this.fetchAllUsers();
      }
  },
//   pocketbase: {
//     collection: 'userScores',
//     stateKey: 'users'
//   }
//   persist: {
//     paths: ['phrases']
//   }
})
