// import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Preferences from '../views/Preferences.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import PasswordReset from '../views/PasswordReset.vue'

import client from '../pocketbase';

import AccountScreen from '../components/AccountScreen.vue';
// import SettingsDevices from '../components/SettingsDevices.vue';
import SettingsCalendar from '../components/SettingsCalendar.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  // {
  //   path: '/preferences',
  //   name: 'Preferences',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "preferences" */ '../views/Preferences.vue')
  // }
  {
    path: '/preferences',
    name: 'Preferences',
    meta: { requiresAuth: true },
    component: Preferences,
    children: [
      {
        path: '',
        component: AccountScreen,
        name: 'Account'
      },
      {
        path: 'calendars',
        component: SettingsCalendar,
        name: 'Calendars'
      },
      // {
      //   path: 'devices',
      //   component: SettingsDevices,
      //   name: 'Devices',
      // },
      
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset
  }
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes // short for `routes: routes`
})

router.beforeEach((to, from) => {
  // Init the store within the beforeEach function as per the documentation:
  // https://pinia.vuejs.org/core-concepts/outside-component-usage.html#single-page-applications
  if (to.meta.requiresAuth && !client?.authStore.isValid) {
      return {
          path: "/login"
      };
  }
});

export default router
