<script setup>
import { ref, onMounted, computed, inject, watch } from "vue"
import { storeToRefs } from 'pinia'
import { useUsersStore } from "../stores/users";

const userStore = useUsersStore()

const { currentUser } = storeToRefs(userStore)

import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    name: yup.string().min(4).max(24).required(),
    company: yup.string().max(24),
  }),
});

const onNameChange = handleSubmit( async (values, {resetForm, setFieldError}) => {
    try {
     await userStore.updateUserInfo(values.name, values.company, pb)
    } catch (error) {
        if (Object.keys(error.data.data).length) {
        
        for (const item in error.data.data) {
          if (Object.hasOwnProperty.call(error.data.data, item)) {
            setFieldError(item, error.data.data[item].message)
          }
        }
        
      } else {
        setFieldError('name', error.message)
      }
    }
});

const canSaveNameChanges = computed(() => {
    return !(name.value == currentUser.value.name && company.value == currentUser.value.company)
})

let pb


onMounted( async () => {
  pb = inject('client')
  await userStore.checkLogin(pb)

  console.log('userStore.currentUser.model', userStore?.currentUser)
  
  
  name.value = pb?.authStore?.model?.name ?? ''
  company.value = pb?.authStore?.model?.company ?? ''
})

const [name, nameAttrs] = defineField('name')
const [company, companyAttrs] = defineField('company')

</script>

<template>
    <form @submit="onNameChange">
  <div class="flex flex-wrap items-start gap-4">
    <div class="flex sm:w-full md:w-8/12 w-8/12">

      <div class="w-6/12 sm:w-7/12">
        <label for="accountName" class="middle block m-0 text-sm font-normal leading-[1.5] py-[.5625rem] mb-4">Name</label>
        <label for="accountCompany" class="middle block m-0 text-sm font-normal leading-[1.5] py-[.5625rem] mb-4">Company/Organization</label>
      </div>
      <div class="w-6/12 sm:w-7/12">
        <input class="input"  id="accountName"  v-model="name" v-bind="nameAttrs"  type="text">
        <span v-show="errors.name" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': errors.name}">{{ errors.name }}</span>
        <input class="input"  id="accountCompany"  v-model="company" v-bind="companyAttrs"  type="text">
      <span v-show="errors.company" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': errors.company}">{{ errors.company }}</span>
      </div>
    </div>
    <div class="w-3/12">
      <input class="btn btn-primary disabled:opacity-25 disabled:cursor-not-allowed w-full" :disabled="!canSaveNameChanges" type="submit" value="Save Changes">
    </div>
  </div>
  <!-- <div class="flex flex-wrap">
    <div class="sm:w-3/12 w-4/12 px-4">
      <label for="accountCompany" class="middle block m-0 text-sm font-normal leading-7">Company/Organization</label>
    </div>
    <div class="w-4/12 px-4">
      <input class="input"  id="accountCompany"  v-model="company" v-bind="companyAttrs"  type="text">
      <span v-show="errors.company" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': errors.company}">{{ errors.company }}</span>
    </div>
    <div class="sm:w-3/12 w-4/12 px-4">
      <input class="btn-primary" :disabled="!canSaveEmailChanges" type="submit" value="Save Changes">
    </div>
  </div> -->
</form>
  

</template>
