<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue"

// const getID = (theme) => `${settingsID}-${theme}`
const currentTime = ref('')
const fullDayName = ref('')
const timeOfDay = ref('')
const fullDate = ref('')

const updateDateTime = () => {
  const now = new Date();
  currentTime.value = now.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
  fullDayName.value = now.toLocaleDateString([], { weekday: 'long' });
  fullDate.value = now.toLocaleDateString(['en-US'], { year: 'numeric', month: 'long', day: 'numeric' });

  timeOfDay.value = now.toLocaleString([], { dayPeriod: 'short'}).replace('in the', '').replace('at ', '').trim()
  const localePeriod = timeOfDay.value.charAt(0).toUpperCase() + timeOfDay.value.slice(1);
  timeOfDay.value = localePeriod
}

const updateFrequency = 1000 * 60// Update every minute to keep time current
let interval
onMounted(() => {
  updateDateTime(); // Initial update
  interval = setInterval(updateDateTime, updateFrequency);
})

onUnmounted(() => {
  clearInterval(interval)
})

const time = computed(() => currentTime.value)
const dayName = computed(() => fullDayName.value)

</script>

<template>
  <div class="grid text-center gap-y-6 auto-rows-auto mt-4">
    <div class="px-4"><h2 class="font-bold text-[5rem] leading-none">{{ dayName }}</h2></div>
    <div class="px-4"><h3 class="text-[4rem] leading-none">{{ timeOfDay }}</h3></div>
    <div class="px-4"><h1 class="font-bold text-8xl leading-none">{{ time }}</h1></div>
    <div class="px-4 mb-4"><h3 class="text-[4rem] leading-none">{{ fullDate }}</h3></div>
  </div>
</template>

<style scoped>
</style>