// import './assets/css/foundation-prototype.min.css'
import { createApp, nextTick } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from './router/index'
// import './assets/css/foundation-prototype.min.css'

import './style.css'


import { pocketbasePlugin } from './pinia-pocketbase'
import client from './pocketbase';

import * as Sentry from "@sentry/vue";


const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)
pinia.use(pocketbasePlugin(client))
const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://1928c748c467ff2bd5661cd3b6baf6cf@o4507460779769856.ingest.us.sentry.io/4507460783505408",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"/*, /^https:\/\/yourserver\.io\/api/*/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// const router = createRouter({
//   // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
//   history: createWebHistory(),
//   routes: [
//     {
//       path: '/',
//       name: 'Home',
//       component: Home
//     },
//     {
//       path: '/preferences',
//       name: 'Preferences',
//       meta: { requiresAuth: true },
//       component: Preferences
//     },
//     {
//       path: '/login',
//       name: 'Login',
//       component: Login
//     },
//     {
//       path: '/register',
//       name: 'Register',
//       component: Register
//     },
//     {
//       path: '/password-reset',
//       name: 'PasswordReset',
//       component: PasswordReset
//     }
//   ]
// })

// router.beforeEach((to, from) => {
//   // Init the store within the beforeEach function as per the documentation:
//   // https://pinia.vuejs.org/core-concepts/outside-component-usage.html#single-page-applications
//   if (to.meta.requiresAuth && !client?.authStore.token) {
//       return {
//           path: "/login"
//       };
//   }
// });

// make v-focus usable in all components
app.directive('focus', {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    nextTick(() => el.focus())
    
  }
})

app.use(pinia)
app.use(router)
app.provide('client',client)
app.mount('#app')
