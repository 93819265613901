import { dateToISODate, getDaysDifference, addDays } from "./dateUtils";



/**
 * Groups upcoming events by date within a specified limit and cutoff period.
 * 
 * @param {Array} events - An array of event objects with 'start' and 'end' properties in ISO format.
 * @param {number} [limit=5] - The maximum number of events to include in the result.
 * @param {number} [daysCutoff=14] - The number of days ahead to consider as upcoming events.
 * @returns {Object} An object where each key is a date in ISO format and the value is an array of events on that date.
 */
export function groupUpcomingEvents(events, limit = 5, daysCutoff = 14) {
    const todayDate = new Date()
    const todayDateISO = dateToISODate(todayDate)
    const cutoffDate = addDays(todayDate, daysCutoff - 1);
    
    
    const groupedEvents = {};
    events
      .filter((event) => {
        return (new Date(event.start)) <= cutoffDate
      })
      .slice(0, limit)
      .forEach((event) => {
  
        const start = new Date(event.start)
        const end = new Date(event.end)
        
        const startDateString = dateToISODate(start)
        const endDateString = dateToISODate(end)
  
        const isMultiDay = startDateString !== endDateString
  
        if (isMultiDay) {
          // Add the event to today/start date and the end date
          
          const isTodayTheStart = todayDateISO === startDateString
          const isTodayAfterTheStart = todayDate > start
          const isTodayTheEnd = todayDateISO === endDateString
          const isTodayBeforeTheStart = todayDate < start
          
          if (isTodayTheStart) {
            if (!groupedEvents[todayDateISO]) {
                groupedEvents[todayDateISO] = [];
              }
              groupedEvents[todayDateISO].push(event);
          } else if (isTodayBeforeTheStart) {
            if (!groupedEvents[startDateString]) {
                groupedEvents[startDateString] = [];
              }
              groupedEvents[startDateString].push(event);
          } else if (isTodayAfterTheStart && !isTodayTheEnd) {
            if (!groupedEvents[todayDateISO]) {
                groupedEvents[todayDateISO] = [];
              }
              groupedEvents[todayDateISO].push(event);
          }

          
          const isEndBeforeCutoff = end < cutoffDate
          if (isEndBeforeCutoff) {
            if (!groupedEvents[endDateString]) {
                groupedEvents[endDateString] = [];
              }
              groupedEvents[endDateString].push(event);
          }
        } else {
          if (!groupedEvents[startDateString]) {
            groupedEvents[startDateString] = [];
          }
          groupedEvents[startDateString].push(event);
        }
        
        
      })
  
      const groupedEventsSorted = {}
      const sortedKeys = Object.keys(groupedEvents).sort((a, b) => new Date(a) - new Date(b));
      sortedKeys.forEach(key => {
        groupedEventsSorted[key] = groupedEvents[key];
      });
      return groupedEventsSorted
  }

  export function timeTilEvent(eventDateString, currentDate = new Date()) {
    const eventDate = new Date(eventDateString);
    
    const timeDifference = eventDate - currentDate;
    let output = "In "
  
    if (timeDifference > 0) {
      const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24));
      const hoursDifference = Math.floor((timeDifference % (1000 * 3600 * 24)) / (1000 * 3600));
      const minutesDifference = Math.round((timeDifference % (1000 * 3600)) / (1000 * 60));
    
      if (daysDifference) {
        // calculate days based on date
        // const otherDaysDifference = getDaysDifference(eventDate, currentDate)
        // output += `${otherDaysDifference} day${otherDaysDifference !== 1 ? "s": ""}`
        return ''
      }
      if (hoursDifference) {
        output += `${hoursDifference} hour${hoursDifference !== 1 ? "s": ""}`
      }
      if (minutesDifference) {
        if (hoursDifference) {
          output += " and "
        }
        output += `${minutesDifference} minute${minutesDifference !== 1 ? "s": ""}`
      } else if (!hoursDifference) {
        output += '1 minute'
      }
      return output;
    } else {
      return "Now"
    }
  }

  export function timeAndDaysTilEvent(eventDateString, currentDate = new Date()) {
    const eventDate = new Date(eventDateString);
    
    const timeDifference = eventDate - currentDate;
    let output = "In "
  
    if (timeDifference > 0) {
      const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24));
      const hoursDifference = Math.floor((timeDifference % (1000 * 3600 * 24)) / (1000 * 3600));
      const minutesDifference = Math.round((timeDifference % (1000 * 3600)) / (1000 * 60));
    
      if (daysDifference) {
        // calculate days based on date
        const otherDaysDifference = getDaysDifference(eventDate, currentDate)
        output += `${otherDaysDifference} day${otherDaysDifference !== 1 ? "s": ""}`
        return output
      }
      if (hoursDifference) {
        output += `${hoursDifference} hour${hoursDifference !== 1 ? "s": ""}`
      }
      if (minutesDifference) {
        if (hoursDifference) {
          output += " and "
        }
        output += `${minutesDifference} minute${minutesDifference !== 1 ? "s": ""}`
      } else if (!hoursDifference) {
        output += '1 minute'
      }
      return output;
    } else {
      return "Now"
    }
  }