<script setup>
import { ref, onMounted, computed, inject, onUnmounted } from "vue"
import { useUsersStore } from "../stores/users";
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
// import { usePhrasesStore } from "../stores/phrases";

// Import the PocketBase JS library
// import client from '../pocketbase';

const loading = ref(false)

import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(6).max(24).required()
  }),
});

const userStore = useUsersStore()

const loginWithPCO = async function() {
  
  

  userStore.loginWithPCO(pb)
}


const onSubmit = handleSubmit(async (values, {resetForm, setFieldError}) => {
  loading.value = true
    try {
      await userStore.login(values.email, values.password, pb)
      resetForm()
      if (userStore.isLoggedIn) {
        // forward to home
        if (userStore.currentUserCode) {
// if the user was logging in for a device, handle that process
          await userStore.addDeviceToUser(pb)
          router.push('/preferences')
        } else {
          if (userStore.loginRedirectPath) {
            router.push(userStore.loginRedirectPath)
            userStore.loginRedirectPath = ''
          } else {
            router.push('/preferences')
          }
        }
        
      }
    } catch (error) {
      loading.value = false
      // show the error
      
      if (Object.keys(error?.data?.data ?? []).length) {
        for (const item in error.data.data) {
          if (Object.hasOwnProperty.call(error.data.data, item)) {
            setFieldError(item, error.data.data[item].message)
          }
        }
        
      } else {
        console.log('after');
        setFieldError('email', error.message)
      }
    }
  
});

const resetPassword = () => {
  userStore.resetPassword(pb)
}

const showContextMenu = () => {
      window.showContextMenu('login')
    }

let pb

onMounted( async () => {
  pb = inject('client')
  await userStore.checkLogin(pb)

  if (userStore.isLoggedIn) {
    // forward to home
    router.push('/preferences')
  }
})

const [email, emailAttrs] = defineField('email')
const [password, passwordAttrs] = defineField('password');
</script>



<template>
  <div class="mx-auto max-w-6xl mt-4">
    <div class="flex flex-wrap">
  <!-- <div class="grid-x grid-padding-x"> -->
    <!-- <div class="cell medium-6 small-8"> -->
    <div class="w-full md:w-1/2 w-8/12 px-4 mx-auto text-center">
      <!-- <div class="w-full md:w-1/2 w-8/12 px-4"> -->
        <h2 class="text-3xl mb-2">Login</h2>
    <form @submit="onSubmit">
      <label class="block m-0 text-sm font-normal leading-7">Email
      <input @click.right="showContextMenu" class="block box-border w-full h-10 mb-4 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-400 leading-6 p-1.5 shadow-inner rounded-md text-neutral-950 border-none border-neutral-50 focus:border focus:transition focus:ease-in-out focus:duration-500 focus:border-neutral-500 focus:border-solid focus:shadow" v-model="email" v-bind="emailAttrs" type="email" autocomplete="username" />
      </label>
      <span v-show="errors.email" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': errors.email}">{{ errors.email }}</span>
      <label class="block m-0 text-sm font-normal leading-7">Password
      <input @click.right="showContextMenu" class="block box-border w-full h-10 mb-4 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-400 leading-6 p-1.5 shadow-inner rounded-md text-neutral-950 border-none border-neutral-50 focus:border focus:transition focus:ease-in-out focus:duration-500 focus:border-neutral-500 focus:border-solid focus:shadow" v-model="password" v-bind="passwordAttrs" type="password" autocomplete="current-password" />
      </label>
      <span v-show="errors.password" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': errors.password}">{{ errors.password }}</span>

      <div v-if="loading" class="flex justify-center items-center h-11 mb-4">
        <span class="block w-11 h-11 rounded-full border-b-transparent border-solid border-4 border-primary-100 animate-spin"></span>
      </div>
      <input v-else class="btn btn-primary block w-full" type="submit" value="Sign In">
    </form>
    <RouterLink class="btn btn-secondary clear" to="/password-reset">Reset Password</RouterLink>
    <RouterLink class="btn btn-secondary hollow" to="/register">Create Account</RouterLink>
    <!-- <a href="/password-reset" class="btn btn-secondary clear" to="">Reset Password</a>
    <a href="/register" class="btn btn-secondary hollow" to="/preferences/register">Create Account</a> -->
    
    </div>
  </div>
  </div>
</template>
