<script setup>
import { ref, onMounted, computed, inject } from "vue"
import { useUsersStore } from "../stores/users";

const userStore = useUsersStore()


import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    password: yup.string().min(8).max(24).required(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required(),
    oldPassword: yup.string().min(8).max(24).required()
  }),
});

const onPasswordChange = handleSubmit( async (values, {resetForm, setFieldError}) => {
    try {
     await userStore.updatePassword(values.password, values.confirmPassword, values.oldPassword, pb)
     resetForm()
    } catch (error) {
      if (Object.keys(error.data.data).length) {
        
        for (const item in error.data.data) {
          if (Object.hasOwnProperty.call(error.data.data, item)) {
            setFieldError(item, error.data.data[item].message)
          }
        }
        
      } else {
        setFieldError('password', error.message)
      }
    }
});

const canSavePasswordChanges = computed(() => {
    if (!newPassword.value || !passwordConfirmation.value || !oldPassword.value) {
        return false
    }
    return true
})

let pb

onMounted( async () => {
  pb = inject('client')
})

const [newPassword, newPasswordAttrs] = defineField('password');
const [passwordConfirmation, passwordConfirmationAttrs] = defineField('confirmPassword');
const [oldPassword, oldPasswordAttrs] = defineField('oldPassword');

</script>

<template>
    <form @submit="onPasswordChange">
  <div class="flex flex-wrap items-start gap-4">
    <div class="flex sm:w-full md:w-8/12 w-8/12">
    <div class="w-6/12 sm:w-7/12">
      <label for="accountPassword" class="middle block m-0 text-sm font-normal leading-[1.5] py-[.5625rem] mb-4">New Password</label>
      <label for="accountPasswordConfirm" class="middle block m-0 text-sm font-normal leading-[1.5] py-[.5625rem] mb-4">Confirm Password</label>
      <label for="accountOldPassword" class="middle block m-0 text-sm font-normal leading-[1.5] py-[.5625rem] mb-4">Old Password</label>
    </div>
    <div class="w-6/12 sm:w-7/12">
      <input class="input"  id="accountPassword" v-model="newPassword" v-bind="newPasswordAttrs" type="password" />
      <span v-show="errors.password" class="form-error" :class="{'is-visible': errors.password}">{{ errors.password }}</span>
      <input class="input"  id="accountPasswordConfirm" v-model="passwordConfirmation" v-bind="passwordConfirmationAttrs" type="password" />
      <span v-show="errors.confirmPassword" class="form-error" :class="{'is-visible': errors.confirmPassword}">{{ errors.confirmPassword }}</span>
      <input class="input"  id="accountOldPassword" v-model="oldPassword" v-bind="oldPasswordAttrs" type="password" />
      <span v-show="errors.oldPassword" class="form-error" :class="{'is-visible': errors.oldPassword}">{{ errors.oldPassword }}</span>
    </div>
    </div>
    <div class="w-3/12">
      <h4 class="subheader"><input class="btn btn-primary disabled:opacity-25 disabled:cursor-not-allowed w-full" :disabled="!canSavePasswordChanges" type="submit" value="Save Changes"></h4>
    </div>
  </div>
</form>
  

</template>
