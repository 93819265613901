<script setup>
import { ref, onMounted, computed, inject, onUnmounted } from "vue"
import { useUsersStore } from "../stores/users";
import { useRouter, useRoute } from 'vue-router'


const router = useRouter()
const route = useRoute()

const loading = ref(false)

import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors:registrationErrors, defineField: defineRegistrationField, handleSubmit: handleRegistrationSubmit } = useForm({
  validationSchema: yup.object({
    name: yup.string().min(4).max(24).required(),
    company: yup.string().max(24),
    email: yup.string().email().required(),
    password: yup.string().min(8).max(24).required(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'passwords must match').required()
  }),
});
const userStore = useUsersStore()

const loginWithPCO = async function() {
  
  

  userStore.loginWithPCO(pb)
}

const onRegistration = handleRegistrationSubmit( async (values, {resetForm, setFieldError}) => {
  // if (userStore.users.map(user => user.name.toLowerCase()).includes(values.username.toLowerCase())) {
  //   setFieldError('username', 'Username taken. Please pick another.')
  // } else {
    try {
      loading.value = true
      await userStore.registerUser(values.name, values.company, values.email, values.password, values.confirmPassword, pb)  
      resetForm()
      // go home
      if (userStore.isLoggedIn) {
        if (userStore.loginRedirectPath) {
          router.go(userStore.loginRedirectPath)
          userStore.loginRedirectPath = ''
        } else {
          router.go('/account')
        }
      }
    } catch (error) {
      loading.value = false
      if (Object.keys(error?.data?.data ?? []).length) {
        for (const item in error.data.data) {
            if (Object.hasOwnProperty.call(error.data.data, item)) {
                setFieldError(item, error.data.data[item].message)
            }
        }
      
      } else {
          setFieldError('email', error.message)
      }
    }
    
  
  
});

const openWebsite = () => {
  window.openWebsite()
}

const showContextMenu = () => {
      window.showContextMenu('login')
    }

let pb

onMounted( async () => {
  pb = inject('client')

  await userStore.checkLogin(pb)

  if (userStore.isLoggedIn) {
    // forward to home
    router.go('/account')
  }
})
const [name, nameAttrs] = defineRegistrationField('name')
const [company, companyAttrs] = defineRegistrationField('company')
const [newEmail, newEmailAttrs] = defineRegistrationField('email')
const [newPassword, newPasswordAttrs] = defineRegistrationField('password');
const [passwordConfirmation, passwordConfirmationAttrs] = defineRegistrationField('confirmPassword');
// const [newPassword, newPasswordAttrs] = defineRegistrationField('password');
</script>

<template>
  <form @submit="onRegistration">
    <div class="mx-auto max-w-6xl mt-4">
    <div class="flex flex-wrap">
      <div class="w-full px-4 mx-auto text-center">
        <h2 class="text-3xl mb-2 text-center leading-normal">Create an Account</h2>
      </div>
      
    <div class="w-full md:w-1/2 w-8/12 px-4 mx-auto">
      <!-- <div class="w-full md:w-1/2 w-8/12 px-4"> -->
        
      <label class="block m-0 text-sm font-normal leading-7">Name
      <input tabindex="1" @click.right="showContextMenu"  class="block box-border w-full h-10 mb-4 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-400 leading-6 p-1.5 shadow-inner rounded-md text-neutral-950 border-none border-neutral-50 focus:border focus:transition focus:ease-in-out focus:duration-500 focus:border-neutral-500 focus:border-solid focus:shadow" v-model="name" v-bind="nameAttrs" type="text" required/>
      </label>
      <span v-show="registrationErrors.name" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': registrationErrors.name}">{{ registrationErrors.name }}</span>
      <label class="block m-0 text-sm font-normal leading-7">Email
      <input tabindex="3" @click.right="showContextMenu"  class="block box-border w-full h-10 mb-4 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-400 leading-6 p-1.5 shadow-inner rounded-md text-neutral-950 border-none border-neutral-50 focus:border focus:transition focus:ease-in-out focus:duration-500 focus:border-neutral-500 focus:border-solid focus:shadow" v-model="newEmail" v-bind="newEmailAttrs" type="email" required autocomplete="username"/>
      </label>
      <span v-show="registrationErrors.email" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': registrationErrors.email}">{{ registrationErrors.email }}</span>
      </div>
        
          
      
    
      <div class="w-full md:w-1/2 w-8/12 px-4 mx-auto">
        <label class="block m-0 text-sm font-normal leading-7">Company/Organization
      <input tabindex="2" @click.right="showContextMenu"  class="block box-border w-full h-10 mb-4 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-400 leading-6 p-1.5 shadow-inner rounded-md text-neutral-950 border-none border-neutral-50 focus:border focus:transition focus:ease-in-out focus:duration-500 focus:border-neutral-500 focus:border-solid focus:shadow" v-model="company" v-bind="companyAttrs" type="text"/>
      </label>
      <span v-show="registrationErrors.company" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': registrationErrors.company}">{{ registrationErrors.company }}</span>
      </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 w-8/12 px-4 mx-auto">
          <label class="block m-0 text-sm font-normal leading-7">Password
      <input tabindex="4" @click.right="showContextMenu"  class="block box-border w-full h-10 mb-4 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-400 leading-6 p-1.5 shadow-inner rounded-md text-neutral-950 border-none border-neutral-50 focus:border focus:transition focus:ease-in-out focus:duration-500 focus:border-neutral-500 focus:border-solid focus:shadow" v-model="newPassword" v-bind="newPasswordAttrs" type="password" required autocomplete="new-password" />
      </label>
      <span v-show="registrationErrors.password" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': registrationErrors.password}">{{ registrationErrors.password }}</span>

      
      <!-- <button @click="showAddScreen = false" type="button" class="button secondary float-right">Cancel</button> -->
    
    
    
    
        </div>
        <div class="w-full md:w-1/2 w-8/12 px-4 mx-auto">
          <label class="block m-0 text-sm font-normal leading-7">Password Confirmation
      <input tabindex="5" @click.right="showContextMenu"  class="block box-border w-full h-10 mb-4 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-400 leading-6 p-1.5 shadow-inner rounded-md text-neutral-950 border-none border-neutral-50 focus:border focus:transition focus:ease-in-out focus:duration-500 focus:border-neutral-500 focus:border-solid focus:shadow" v-model="passwordConfirmation" v-bind="passwordConfirmationAttrs" type="password" required autocomplete="new-password" />
      </label>
      <span v-show="registrationErrors.confirmPassword" class="form-error block mb-4 -mt-2.5 text-xs font-bold text-rose-600" :class="{'is-visible': registrationErrors.confirmPassword}">{{ registrationErrors.confirmPassword }}</span>
        </div>
      </div>

      <div class="flex flex-wrap mb-4">
      <!-- <div class="grid-x grid-padding-x margin-bottom-1"> -->
        <div class="w-1/2 px-4 mx-auto text-center">
          <div v-if="loading" class="flex justify-center items-center h-11 mb-4">
            <span class="block w-11 h-11 rounded-full border-b-transparent border-solid border-4 border-primary-100 animate-spin"></span>
          </div>
          <input v-else tabindex="6" @click.right="showContextMenu" class="btn btn-primary block w-full" type="submit" value="Create Account">

          <small>By clicking create account, you agree to our <a class="text-primary-100" href="/terms-of-service">Terms of Service</a> and <br><a class="text-primary-100" href="/privacy-policy">Privacy Policy</a>.</small>
        </div>
      </div>


      <div class="flex flex-wrap">
        <div class="w-1/2 px-4 mx-auto text-center">
          <!-- <RouterLink class="button hollow secondary expanded round" to="/preferences/login">Sign In</RouterLink> -->
    <!-- <a href="/login" class="btn btn-secondary hollow" to="/preferences/register">Sign In</a> -->
    <RouterLink class="btn btn-secondary hollow" to="/login">Sign In</RouterLink>
        </div>

      </div>
</div>
      
    

</form>
</template>

<style>
/* .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }  */
</style>